import styled from "styled-components";

export const NumberAdventuresWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 95vh;
  justify-content: space-around;

  .section-2-c-left {
    flex-basis: 50%;
    height: 100%;
    p {
      text-transform: capitalize;
      color: #fff;
      font-size: 50px;
      font-weight: bolder;
      text-align: center;
      padding: 0 4rem;
    }
  }

  .section-2-c-right {
    flex-basis: 50%;
    height: 100%;
    overflow: hidden;
    position: relative;
    img {
      position: absolute;
      transform: matrix(0.97, 0.24, -0.2, 0.79, 0, 0);
      height: 500px;
      width: 100%;
      border-radius: 50px;
      box-shadow: 0px 3px 50px #f6f6f629;
      right: -70px;
    }
  }

  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
