import styled from "styled-components";

export const LearnTPWrapper = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .flexible {
    flex-wrap: wrap;
  }

  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
