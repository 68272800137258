import { AlphaCandiesWrapper } from "./alphacandies.styles";
import Alphabet from "../../../../assets/img/alphabetphonetic.png";
import LetterA from "../../../../assets/img/letterA.png";
import LetterB from "../../../../assets/img/letterB.png";
import LetterS from "../../../../assets/img/letterS.png";
import LetterI from "../../../../assets/img/letterI.png";
import LetterP from "../../../../assets/img/letterP.png";
const Index: React.FC = () => {
  return (
    <AlphaCandiesWrapper>
      <div className="section-2-b-left flex">
        <div className="flex">
          <img alt="" className="letter" src={LetterA} />
          <img alt="" className="letter" src={LetterB} />
        </div>
        <div className="flex">
          <img alt="" className="letter" src={LetterS} />
          <img alt="" className="letter" src={LetterI} />
          <img alt="" className="letter" src={LetterP} />
        </div>
      </div>
      <div className="section-2-b-right flex">
        {/* <img alt="" src={Alphabet} /> */}
        <p>master the alphabets & phonetics with the alphacandies</p>
      </div>
    </AlphaCandiesWrapper>
  );
};

export default Index;
