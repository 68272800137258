import { Genius } from "./genius.styles";
import Logo from "../../../assets/img/layer1@2x.png";
import GeniusLogo from "../../../assets/img/Genius-1@2x.png";
import Separatoraline from "../../../assets/img/roughborder.png";

const Index: React.FC = () => {
  return (
    <Genius>
      {/* <img src={Overlay} /> */}
      <div className="separator-line">
        <img alt="" src={Separatoraline} />
      </div>
      <div className="genius">
        <div className="logo-holder">
          <img alt="" className="logo" src={Logo} />
          <img alt="" className="genius-logo" src={GeniusLogo} />
        </div>
        <div className="genius-text">
          <p className="unleash">
            Unleash Your Childs <b>Genius</b> Using Africa's Premiere Gamified
            Learning Platform
          </p>
          <p className="learning">learning through games for your 6 - 14 year old</p>
        </div>
      </div>
    </Genius>
  );
};

export default Index;
