import { LearnTPWrapper } from "./learnthroughplay.styles";
import Gamepad from "../../../../assets/img/gamepad.png";
import LearnTruPlay from "../../../../assets/img/learntruplay.png";
import LearningFun from "../../../../assets/img/learningthatisfun.png";
import ThinkingSkills from "../../../../assets/img/im2.png";
import Creativity from "../../../../assets/img/im1.png";
import SEI from "../../../../assets/img/im3.png";
import MathImg from "../../../../assets/img/mathpad.png";
import Reading from "../../../../assets/img/readingpad.png";
import LearnMoreButton from "../../../../assets/img/leanrmorebtn.png";

const Index: React.FC = () => {
  return (
    <LearnTPWrapper>
      <div>
        <img alt="" src={LearnTruPlay} />
      </div>
      <div>
        <img alt="" src={Gamepad} />
      </div>
      <div>
        <img alt="" src={LearningFun} />
      </div>
      <div className="flexible">
        <img alt="" src={ThinkingSkills} />
        <img alt="" src={Creativity} />
        <img alt="" src={MathImg} />
        <img alt="" src={Reading} />
        <img alt="" src={SEI} />
      </div>
      <div>
        <img alt="" src={LearnMoreButton} />
      </div>
    </LearnTPWrapper>
  );
};

export default Index;
