import styled from "styled-components";
import ListDot from "../../../assets/img/listdot.png";

export const ExpectedOutcomeWrapper = styled("div")`
  height: max-content;
  display: flex;
  background: #fff;
  align-items: center;
  flex-direction: column;
  /* padding: 3rem 0px 0px 0px; */
  position: relative;

  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .separating-line {
    position: absolute;
    top: -25px;
    padding: 0;
    width: 100%;
  }
  h2 {
    color: #EA8337;
    font-size:40px;
    font-weight: bolder;
  }
  h4{
      color: #693403;
  }

  .scrols {
    flex-wrap: wrap;
    width: 80%;

    .each-scroll {
      position: relative;

      .list-dot {
        background: url(${ListDot}) center no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        height: 50px;
        width: 50px;
        right: 120px;
        top: 13px;

        img {
          height: 30px;
          width: 30px;
        }
      }

      img {
        height: 250px;
        width: 300px;
        margin: 20px 10px;
      }
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 140px;
        text-align: center;
        top: 4.5rem;
        left: 2.5rem;
        font-size: 15px;
      }
    }
  }
  .mandela {
    width: 100%;
    height: 70vh;
    margin-top: 5rem;

    .mandela-left {
      flex-direction: column;
      align-items: flex-start;
      width: 40%;
      height: 100%;

      img {
        width: 40%;
      }
      .arrow {
        width: 100%;
        margin: 40px 0;
      }
      .opportunity {
        width: 80%;
        height: 40%;
      }
    }
    .mandela-right {
      width: 50%;
      height: 100vh;
      position: relative;

      img:first-child {
        width: 70%;
        height: 70%;
      }
      img:last-child {
        width: 145px;
        height: 155px;
        position: absolute;
        top: 158px;
        right: 165px;
      }
      .education {
        position: absolute;
        height: 100px;
        width: 200px;
        bottom: 120px;
      }
    }
  }
`;
