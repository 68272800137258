import styled from "styled-components";

export const AlphaCandiesWrapper = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  .section-2-b-left {
    /* width: 50%; */
    flex-basis: 50%;
    flex-direction: column;
    padding: 10px 0;
    align-items: center;
    .letter {
      height: 250px;
      width: 250px;
    }
  }

  .section-2-b-right {
    flex-basis: 50%;
    height: 100%;

    p {
      text-transform: capitalize;
      color: #fff;
      font-size: 50px;
      font-weight: bolder;
      text-align: center;
      padding: 0 3rem;
    }
  }

  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
