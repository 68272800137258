import styled from "styled-components";
import MathQuest from "../../../assets/img/mathquest.png";

export const KnowledgeQuestGameWrapper = styled("div")`
  background: url(${MathQuest}) center no-repeat;
    background-size: cover;
    height: 120vh;
    position: relative;

.separating-line{
  /* position: absolute; */
  margin-top: -20px;
  width: 100%;
}
`;
