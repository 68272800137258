import { FooterWrapper } from "./footer.styles";
import Line from "../../../assets/img/roughborder.png";
import Logo from "../../../assets/img/logo.png";
import Twitter from "../../../assets/img/twitter.png";
import Snapchat from "../../../assets/img/snapchat.png";
import Mail from "../../../assets/img/mail.png";
import FB from "../../../assets/img/fb.png";

const Index: React.FC = () => (
    <FooterWrapper>
            <img className="separating-line-2" src={Line} alt="" />
      <header className="flex">
        <div className="logo flex">
          <img src={Logo} alt="" />
        </div>
        <ul className="nav flex">
          <li>Home</li>
          <li>Cradle</li>
          <li>Genius</li>
          <li>About</li>
          <li>Contact</li>
        </ul>
        <ul className="social flex">
          <li>
            <img src={Twitter} alt="" />
          </li>
          <li>
            <img src={Snapchat} alt="" />
          </li>
          <li>
            <img src={Mail} alt="" />
          </li>
          <li>
            <img src={FB} alt="" />
          </li>
        </ul>
      </header>
      <body>
        <p>
          EmBED is the first of its kind in Africa. An organisation that
          leverages best in-class teachers, media, and technology to create
          high-quality, affordable and accessible education for African
          students. The uLesson app offers students in Junior and Senior
          Secondary School a holistic learning experience in subjects such as
          Mathematics, English Language, Basic Science, Basic Technology,
          Business Studies, Physics, Chemistry and Biology, while also preparing
          Senior School students for WASSCE, JAMB, NECO and GCE exams.
        </p>
        <p>
        Our app
          features over 10,000 questions in Mathematics, English Language,
          Physics, Chemistry and Biology, with detailed solutions; making it an
          excellent tool for preparing for and cracking WASSCE. Students in
          SSS/SHS 3 classes in Nigeria, Ghana, Sierra Leone, Liberia and the
          Gambia can take advantage of this quality resource put together by
          excellent lesson teachers to pass their exams. Timed practice exams
          available on the app not only help SSS/SHS 3 students to measure their
          preparedness, but also to learn time management, exam formats, and
          accuracy skills.
        </p>
      </body>
    </FooterWrapper>
  );


export default Index;
