import styled from "styled-components";
import BG from "../../../assets/img/Rectanglex.png";

export const EnrolYourChildWrapper = styled("div")`
  background: url(${BG}) center no-repeat;
  height: max-content;
  width: 100%;

  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .separating-line {
    padding: 0;
    margin-top: -25px;
    width: 100%;
    height: 100px;
    /* border: 1px solid red; */
  }

  .enrol {
    color: #ff6600;
    font-weight: bolder;
    font-size: 30px;
    margin-top: -10px;
    padding-left: 50px;
  }
  .enrol-i {
    color: #fff;
    font-weight: bolder;
    font-size: 20px;
  }

  .devices-os {
    justify-content: space-between;
    height: 50%;
    width: 100%;

    .devices {
      height: 100%;
      width: 50%;

      img {
        width: 100%;
        height: 125%;
      }
    }
    .os-holder {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      width: 50%;

      p:first-child {
        color: #fff;
        font-size: 35px;
        font-weight: bold;
      }
      .download {
        color: #fff;
      }
      .os {
        justify-content: space-evenly;
        .OS {
          width: 50px;
          height: 40px;
        }
      }
    }
  }
  .free-month {
    justify-content: space-between;
    margin: 60px 0px;
    /* border: 1px solid red; */
    height: 45vh;
    .text {
      color: #fff;
      font-size: 30px;
      font-weight: bold;
      width: 50%;
      /* border: 1px solid red; */
      span {
        font-size: 15px;
      }
    }
    .form-holder {
      height: 50px;
      /* border: 1px solid red; */
      width: 50%;
      /* width: 50%; */

      form {
        background: #fff;
        height: 100%;
        width: 70%;
      border-radius: 50px;
        overflow: hidden;
        border: 1px solid none;
      }
      .text-input {
        width: 70%;
        height: 100%;
        border: none;
        text-indent: 20px;
      }
      .button {
        cursor: pointer;
        width: 30%;
        height: 100%;
        border-radius: 50px;
        background: #ff6600;
        border: none;
      }
      .checkbox-holder{
        padding-left: 15px;
      }
      .checkbox{
        color: red;
        border-radius: green;
        /* border: 1px solid green; */

      }
      a{
        color: #ff660095;
        font-weight:bold;
        text-decoration: underline;
      }
    }
  }
  
`;
