import { Line } from "../HeroSection";
import { KnowledgeQuestGameWrapper } from "./knowledgequestgame.styles";

const Index: React.FC = () => {
  return (
    <>
      <KnowledgeQuestGameWrapper></KnowledgeQuestGameWrapper>
      {/* <Line /> */}
    </>
  );
};

export default Index;
