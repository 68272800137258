import { EnrolYourChildWrapper } from "./enrolyourchild.styles";
import Line from "../../../assets/img/roughborder.png";
import Windows from "../../../assets/img/windows.png";
import Android from "../../../assets/img/android.png";
import Devices from "../../../assets/img/devices.png";
import Linux from "../../../assets/img/linux.png";
import IOS from "../../../assets/img/IOS.png";

const Index: React.FC = () => {
  return (
    <EnrolYourChildWrapper>
      <img className="separating-line" src={Line} alt="" />
      <p className="enrol">
        Enrol <br />
        <p className="enrol-i">Your Child Now!!!</p>
      </p>
      <div className="flex devices-os">
        <div className="devices flex">
          <img src={Devices} alt="" />
        </div>
        <div className="os-holder">
          <p>
            One Account <br />
            Across All Devices
          </p>
          <p className="download">Download For</p>
          <div className="flex os">
            <img className="OS " src={Windows} alt="" />
            <img className="OS " src={Linux} alt="" />
            <img className="OS " src={IOS} alt="" />
            <img className="OS " src={Android} alt="" />
          </div>
        </div>
      </div>
      <div className="free-month flex">
        <div className="text">
          Get you first month free <br />
          <span>Then just #4000/mo.,until canceled</span>
        </div>
        <div className="form-holder">
          <form className="flex">
            <input
              className="text-input"
              type="text"
              name="email"
              placeholder="Your Email"
            />
            <input
              className="button"
              type="submit"
              name="Submit"
              defaultChecked
            />
          </form>
          <br />
          <div className="checkbox-holder">
            <input
              className="checkbox"
              type="checkbox"
              name=""
              defaultChecked
              placeholder=""
            />
            <br />
            <a href="#">Privacy Policy</a>
          </div>
        </div>
      </div>
    </EnrolYourChildWrapper>
  );
};

export default Index;
