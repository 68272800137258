import styled from "styled-components";

export const FooterWrapper = styled("div")`
  height: 100vh;
  /* padding-top: 5px; */
  position: relative;

  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .separating-line-2{
    position: absolute;
    top: -75px;
    width: 100%;
    /* height: 100px; */
    /* border: 1px solid red; */
  }
  header {
    justify-content: space-between;
    width: 100%;
    /* margin-top: 30px; */
    height: 20%;
    align-items: flex-end;

    .logo {
      width: 20%;
      cursor: pointer;
    }
    .nav {
      width: 60%;
      justify-content: space-evenly;
      li {
        cursor: pointer;
        list-style: none;
        font-weight: bold;
        color: #653100;
      }
    }
    .social {
      width: 20%;

      li {
        display: flex;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        list-style: none;
        margin-right: 5px;
        img {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
  body {
    p {
      color: #653100;
      font-weight: bold;
      padding: 20px;
    }
  }

`;
