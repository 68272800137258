import { SuccessStoryWrapper } from "./success-stories.styles";
import Pics from "../../../assets/img/Ellipse@2x.png";
import Pics1 from "../../../assets/img/kale.jpg";
import Pics2 from "../../../assets/img/Ellipse2.png";
import Quote from "../../../assets/img/quote.png";


const Index: React.FC = () => {
 
  return (
    <SuccessStoryWrapper>
      <div className="flex left">
        <div className="left-main">
          <div className="embed">
            And <span>EmBED</span> Changed Their Lives
          </div>
          <div className="stories">Our Success Stories</div>
          <div className="flex details">
            <div className="img-holder">
              <img src={Pics1} alt="" />
            </div>
            <div className="name">
              <div className="">Kayode Kaleyemi</div>
              <div className="">Nigeria</div>
            </div>
          </div>
          <div className="card">
            <div className="absolute-quote flex">
              <img src={Quote} alt="" />
            </div>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
            </p>
          </div>
        </div>
      </div>

      <div className="right flex">
        {/* <div className="center-image"> */}
        <img src={Pics1} alt="" />
        <div className="dot dot-image1"></div>
        <div className="dot dot-image2">
          <img src={Pics2} alt="" />
        </div>
        <div className="dot dot-image3"></div>
        <div className="dot dot-image4"></div>
        <div className="dot dot-image5"></div>
        <div className="dot dot-image6">
          <img src={Pics} alt="" />
        </div>
        {/* </div> */}
        <div className="circle-1 flex">
          <div className="circle-2 flex">
            <div className="circle-3 flex">
              <div className="circle-4"></div>
            </div>
          </div>
        </div>
      </div>
    </SuccessStoryWrapper>
  );
};

export default Index;
