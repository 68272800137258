import { ExpectedOutcomeWrapper } from "./expectedoutcome.styles";
import Cloud from "../../../assets/img/cloud@2x.png";
import Mandela from "../../../assets/img/Mandela.png";
import Education from "../../../assets/img/Education@2x.png";
import Remember from "../../../assets/img/REMEMBER@2x.png";
import Arrow from "../../../assets/img/Group41@2x.png";
import Opportunity from "../../../assets/img/opportunity.png";
import Scroll1 from "../../../assets/img/scroll1.png";
import ListDot from "../../../assets/img/listdot.png";
import Badge1 from "../../../assets/img/badge1.png";
import Badge2 from "../../../assets/img/badge2.png";
import Badge3 from "../../../assets/img/badge3.png";
import Badge4 from "../../../assets/img/badge4.png";
import Badge5 from "../../../assets/img/badge5.png";
import Line from "../../../assets/img/roughborder.png";

let Scrolls = [
  {
    icon: ListDot,
    scroll: Scroll1,
    badge: Badge1,
    text: "Improve thinking ability through active learning",
  },
  {
    icon: ListDot,
    scroll: Scroll1,
    badge: Badge2,
    text: "Create Opportunities for tomorrow by making them tech savvy digital natures",
  },
  {
    icon: ListDot,
    scroll: Scroll1,
    badge: Badge3,
    text: "Simplify maths and other subjects and make learning fun and easy",
  },
  {
    icon: ListDot,
    scroll: Scroll1,
    badge: Badge4,
    text: "Enhance their creativity by applying acquired knowledge to everyday situations",
  },
  {
    icon: ListDot,
    scroll: Scroll1,
    badge: Badge5,
    text: "Develop problem solving abilities using the LCEE(Learn,create, Experiment & Explore) principles",
  },
];
const Index: React.FC = () => {
  return (
    <ExpectedOutcomeWrapper>
      {/* <img className="separating-line" src={Line} alt="" /> */}
      <h2>Expected Outcomes</h2>
      <h4>What Your Child Stands to gain In EmBED</h4>
      <div className="scrols flex">
        {Scrolls.map(({ scroll, text, icon, badge }) => {
          console.log(scroll, "details");
          return (
            <div className="each-scroll">
              <div className="list-dot">
                <img alt="" src={badge} />
              </div>
              <img alt="" src={scroll} />
              <span className="scroll-text ">{text}</span>
            </div>
          );
        })}
      </div>
      <div className="mandela flex">
        <div className="mandela-left flex">
          <img className="" src={Remember} alt="" />
          <img className="arrow" src={Arrow} alt="" />
          <img className="opportunity" src={Opportunity} alt="" />
        </div>
        <div className="mandela-right flex">
          <img className="cloud" src={Cloud} alt="" />
          <img className="education" src={Education} alt="" />
          <img className="mandela-text" src={Mandela} alt="" />
        </div>
      </div>
    </ExpectedOutcomeWrapper>
  );
};

export default Index;
