import { EmbedCradleWrapper } from "./embedcradle.styles";
import LearnWE from "./LearnWithEmBED";
import AlphaCandies from "./AlphaCandies";
import NumberAdventures from "./NumberAdventures";
import LearnTP from "./LearnThroughPlay";
import Genius from "../Genius";
import KnowledgeQuestGame from "../KnowledgeQuestGame";
import ExpectedOutcome from "../ExpectedOutcome";
import SuccessStories from "../SuccessStories";
import EnrolYourChild from "../EnrolYourChild";
import Footer from "../Footer";
import { Line } from "../HeroSection";

const Index: React.FC = () => {
  return (
    <>
      <EmbedCradleWrapper>
        <LearnWE />
        <AlphaCandies />
        <NumberAdventures />
        <LearnTP />
        <Genius />
        <Line />
        <KnowledgeQuestGame />
        <Line />
        <ExpectedOutcome />
      </EmbedCradleWrapper>
      <SuccessStories />
      <EnrolYourChild />
      <Footer />
    </>
  );
};

export default Index;
