import { NumberAdventuresWrapper } from "./numberadventures.styles";
// import NumberAdventure from "../../../../assets/img/numberadventures.png";
import Greenfield from "../../../../assets/img/greenfield.png";

const Index: React.FC = () => {
  return (
    <NumberAdventuresWrapper>
      <div className="section-2-c-left flex">
        <p>
          explore the amazing world of numbers and math with numventures (number
          ventures)
        </p>
      </div>
      <div className="section-2-c-right flex">
        <img alt="" src={Greenfield} />
      </div>
    </NumberAdventuresWrapper>
  );
};

export default Index;
