import styled from "styled-components";

export const SuccessStoryWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  height: 100vh;
  width: 100%;

  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .left {
    width: 100%;
    .left-main {
      flex-basis: 50%;
      height: 100%;

      .embed {
        width: max-content;
        color: #8bbd43;
        font-size: 40px;
        font-weight: bolder;
        span {
          color: orangered;
        }
      }
      .details {
        padding: 30px 0px;
        justify-content: flex-start;

        .img-holder {
          object-fit: contain;
          width: 120px;
          height: 120px;
          padding: 5px;

          img {
            border-radius: 50%;
            height: 100%;
            width: 100%;
          }
        }
      }
      .name {
        height: 100%;
        width: 60%;
        margin-left: 30px;

        div:first-child {
          font-weight: bolder;
          color: #693403;
        }
        div:last-child {
          color: #693403;
          opacity: 0.6;
          font-weight: bold;
        }
      }
      .stories {
        color: #693403;
        font-size: 28px;
        font-weight: bolder;
      }

      .card {
        box-shadow: 0px 3px 30px #00000017;
        border-radius: 20px 0px 20px 20px;
        padding: 20px;
        opacity: 1;
        width: 500px;
        position: relative;
        text-align: justify;

        .absolute-quote {
          box-shadow: 0px 3px 20px #00000017;
          border-radius: 20px;
          opacity: 1;
          width: 92px;
          height: 68px;
          position: absolute;
          right: 95px;
          top: -35px;
          background: #fff;

          img {
            width: 40%;
            height: 40%;
          }
        }

        p {
          color: #693403;
          font-weight: bolder;
        }
      }
    }
  }
  .right {
    height: 100%;
    width: 50%;
    position: relative;

    img {
      position: absolute;
      width: 150px;
      height: 150px;
      border-radius: 50%;
    }

    .dot {
      position: absolute;
    }

    .dot-image1 {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      background: #f86f1f;
      top: 15rem;
      left: 30px;
    }

    .dot-image2 {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 5px solid orangered;
      top: 65px;
      left: 182px;
      object-fit:contain;
      img{
        height: 100%;
        width: 100%;
      }
    }

    .dot-image3 {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background: #8bbd43;
      top: 250px;
      right: 4rem;
    }

    .dot-image4 {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background: #f86f1f;
      opacity: 0.4;
      top: 193px;
      right: 4rem;
    }

    .dot-image5 {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      bottom: 12.5rem;
      right: 9rem;
      background: #8bbd43;
    }

    .dot-image6 {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 5px solid green;
      bottom: 5.9rem;
      left: 105px;

      object-fit:contain;
      img{
        height: 100%;
        width: 100%;
      }
    }

    .circle-1 {
      border: 1px solid green;
      border-radius: 50%;
      width: 409px;
      height: 409px;
      border: 2px dashed #f86f1f;
      opacity: 0.1;

      .circle-2 {
        background: #f86f1f;
        border-radius: 50%;
        width: 309px;
        height: 309px;

        .circle-3 {
          background: #8bbd43;
          border-radius: 50%;
          width: 250px;
          height: 250px;

          .circle-4 {
            background: white;
            border-radius: 50%;
            width: 150px;
            height: 150px;
            position: relative;
          }
        }
      }
    }
  }
`;
