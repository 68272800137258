import HeroSection, { Line } from "./HeroSection/index";
import Body from "./EmbedCradle";
import { LandingPageWrapper } from "./landingpage.styles";

const Index: React.FC = () => {
  return (
    <LandingPageWrapper>
      <HeroSection />
      <Line />
      <Body />
    </LandingPageWrapper>
  );
};

export default Index;
