import styled from "styled-components";
import LeaderscharBG from "../../../assets/img/MaskGroup7@2x.png";
import Overlay from "../../../assets/img/rectangle1.png";

export const Genius = styled("div")`
  background: url(${LeaderscharBG}) top no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  position: relative;
  margin-top: 4rem;

  .separator-line {
    position: absolute;
    top: -20px;
    width: 100%;
    img {
      width: 100%;
    }
  }

  .genius {
    background: url(${Overlay}) center no-repeat;
    background-size: cover;
    /* border: 1px solid blue; */
    height: 100%;
    width: 60%;
    float: left;
    padding-left: 5rem;

    .logo-holder {
      /* border: 1px solid red; */
      margin-top: 4rem;
      height: 200px;
      width: 300px;
      position: relative;

      .logo {
        height: 100%;
        width: 100%;
      }
      .genius-logo {
        position: absolute;
        top: 7.5rem;
        left: 7rem;
        height: 55px;
        margin-left: 1rem;
      }
    }

    .genius-text {
      .unleash {
        color: #fff;
        font-size: 30px;
        font-weight: bolder;
        font-family: "Roboto", sans-serif;
       
        b {
          color: #ff6600;
          font-size: 45px;
        }
      }
      .learning {
        color: #ff6600;
        font-size: 23px;
        margin-left: 2rem;
      }
    }
  }
`;
